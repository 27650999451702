<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->
<template>
  <div class="scroll-container">
    <code-diff v-if="oldJson && oldJson !== newJson" 
      :old-string="oldJson" 
      :new-string="newJson" 
      :context="3"
      language="json"
      renderNothingWhenEmpty />

    <pre class="config-preview" v-if="!oldJson || oldJson === newJson">
      {{ newJson }}
    </pre>
  </div>
</template>

<script>
import CodeDiff from 'vue-code-diff'

export default {
  name: "TransactionDiff",
  components: {
    CodeDiff,
  },
  props: {
    oldVal: {
      type: String,
      required: true,
    },
    newVal: {
      type: String,
      required: true,
    },
    isClear: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      oldJson: '',
      newJson: '',
    }
  },
  mounted() {
    this.init()
  },
  computed: {},
  watch: {
    oldVal(oldValue, newValue) {
      this.init()
    },
    newVal(oldValue, newValue) {
      this.init()
    }
  },

  methods: {
    init() {
      if (this.isClear) {
        this.oldJson = this.oldVal
        this.newJson = this.newVal
      } else {
          this.oldJson = atob(this.oldVal || '')
          this.newJson = atob(this.newVal || '')
        }
      }
    }
  }
</script>

<style scoped>
.scroll-container {
  max-height: 450px;
  overflow-y: scroll;
}

.config-preview {
  margin-bottom: 0;
  overflow-x: hidden;
  white-space:pre-wrap;
}
</style>
/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import i18n from '../../../../i18n';

const ConfigUpdateType = {
    EXCLUSIVE: {code: 'EXCLUSIVE', text: i18n.t('common.bchSelect[0]')},
    OR: {code: 'OR', text: i18n.t('common.bchSelect[1]')},
    AND: {code: 'AND', text: i18n.t('common.bchSelect[2]')},
    MAJORITY: {code: 'MAJORITY', text: i18n.t('common.bchSelect[3]')}
  }
  
const TRANSACTION_QUORUM = 50;

const ZERO_TRANZACTION_ID = '00000000-0000-0000-0000-000000000000'

export {
    ConfigUpdateType as ChannelConfigUpdateType,
    TRANSACTION_QUORUM,
    ZERO_TRANZACTION_ID
}